import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationEnd, NavigationStart } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { CryptoUtils, Logger } from 'msal';
import { AzureAuthService } from './services/azure-auth-service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isIframe = false;
  loggedIn = false;

  constructor(
    private auth: AuthService,
    private router: Router, 
    private loadingSpinner: Ng4LoadingSpinnerService,
    // private broadcastService: BroadcastService, 
    // private authService: MsalService,
    private azureAuth: AzureAuthService
    ) {}

   ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.azureAuth.initAuthService();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // console.log(this.router.navigated, this.azureAuth.loggedIn)
        var logedInStatus = localStorage.getItem("loggedInStatus") == "true";
        if (!this.router.navigated && logedInStatus) {
          this.azureAuth.refreshAuthentication();
        }
      }
  });

    // this.checkoutAccount();

    // this.broadcastService.subscribe('msal:loginSuccess', () => {
    //   this.checkoutAccount();
    // });

    // this.authService.handleRedirectCallback((authError, response) => {
    //   if (authError) {
    //     console.error('Redirect Error: ', authError.errorMessage);
    //     return;
    //   }

    //   console.log('Redirect Success: ', response);
    // });

    // this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
    //   console.log('MSAL Logging: ', message);
    // }, {
    //   correlationId: CryptoUtils.createNewGuid(),
    //   piiLoggingEnabled: false
    // }));
    //  this.auth.handleLoginCallback().then(res=>{
    //   if(!res)
    //    this.auth.login();
    // })

    // this.router.events.subscribe(event => {
    //   // To show loading spinner, while lazy loading modules
    //   if (event instanceof RouteConfigLoadStart) {
    //     this.loadingSpinner.show();
    //   } else if (event instanceof RouteConfigLoadEnd) {
    //     this.loadingSpinner.hide();
    //   }
    //   if (event instanceof NavigationEnd) {
    //     (<any>window).ga('set', 'page', event.urlAfterRedirects);
    //     (<any>window).ga('send', 'pageview');
    //     window.scroll({behavior: "smooth", top: 0});
    //   }
    // });

    }


    // login() {
    //   const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
  
    //   if (isIE) {
    //     this.authService.loginRedirect();
    //   } else {
    //     this.authService.loginPopup();
    //   }
    // }
  
    // logout() {
    //   this.authService.logout();
    // }
}
