import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { MsalGuard } from '@azure/msal-angular';
import { NgModule } from '@angular/core';

const appRoutes: Routes = [
  {
    path: 'home',
    loadChildren: "./home/home.module#HomeModule",
    canActivate: [MsalGuard]
  },
  {
    path: 'RecentStudies',
    loadChildren: "./recent-studies/recent-studies.module#RecentStudiesModule",
    
  },
  {
    path: '',
    loadChildren: "./search/search.module#SearchModule",
    canActivate: [MsalGuard],
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }

]

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, 
      {
        enableTracing: false,
        useHash: false,
        initialNavigation: isiniframe() ? 'disabled' : undefined // <-this
      }
    )
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export function isiniframe() {
  return window !== window.parent && !window.opener;
}

