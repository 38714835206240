import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { User } from '../models/user';
import { TokenType, Constants } from '../app.constants';
import { AzureAuthService } from './azure-auth-service';

@Injectable({
  providedIn: 'root'
})

export class TokenInterceptor implements HttpInterceptor {
  private spinnerCount: number = 0;
  constructor(private auth: AuthService, private router: Router, private spinnerService: Ng4LoadingSpinnerService, private azureAuth: AzureAuthService) { }
  appUser: User;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.spinnerCount += 1;
    if (this.azureAuth.isLoadingService$.value == true)
      this.spinnerService.show();
    const authtoken = this.azureAuth.getAuthToken();
    // var x = this.auth.getAppUser();
    this.appUser = this.azureAuth.appUser$.value;
    if (this.appUser == null) {
      this.appUser = this.azureAuth.getAppUser();
    }
    let apitoken = null;
    if (authtoken && authtoken != null) {
      if (this.appUser) {
        //set token
        if (this.azureAuth.apiToken$.value == TokenType.ADALTOKEN)
          apitoken = this.appUser.token.adal_token;
        else if (this.azureAuth.apiToken$.value == TokenType.STUDYAPITOKEN)
          apitoken = this.appUser.token.study_api_token;
        else
          this.azureAuth.apiToken$.next(null);

        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apitoken}`
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
          }
        });
      }
    }

    return next.handle(request)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.spinnerCount -= 1;
            if (this.spinnerCount == 0) {
              this.spinnerService.hide();
            }
          }
        }, err => {
          if (err instanceof HttpErrorResponse) {
            this.spinnerCount -= 1;
            if (this.spinnerCount == 0) {
              this.spinnerService.hide();
            }
          }
          if (err.status == 401) {
            this.azureAuth.localLogout();
            //session expired
            this.azureAuth.login();
          }
         else if (err.status == 500) {
          this.router.navigate([this.azureAuth.onAuthSuccessUrl]);
          }
          else if (err.status == 403) {
            this.router.navigate([this.azureAuth.onAuthSuccessUrl]);
            }
        }),
      );
  }
}