export class TokenType {
    public static get ADALTOKEN(): string { return "ADAL_Token"; };
    public static get STUDYAPITOKEN(): string { return "StudyAPIToken"; };

}

export class ResultStatus{
    NotSet;
    Unknown;
    Scheduled;
    Arrived;
    ExamBegun;
    ExamEnded;
    NeedsRvw;        
    Completed;
    Impression;
    Preliminary;
    Final;
    Addendum;
    Revised  
}

//date constants
export class Constants {
    static readonly DATE_FMT = 'MM/dd/yyyy';
    static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss a`;

    //session name
    public static ADALToken = "ADAL_Token";
    public static StudyAPIToken = "StudyAPIToken";
    public static OrgCode = "OrgCodes";
    public static Token="Tokens";
    public static PayLoad="PayLoad";
    public static EunityConfig="EunityConfig";
    public static AuthToken="AuthToken";
    public static ExpiresIn="ExpiresIn";
    public static Permission="Permission";
    public static DisableOrgCodes="DisableOrgCodes";
    public static AppUser="AppUser";
  }