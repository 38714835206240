import { Injectable, OnDestroy } from "@angular/core";
import { MsalService, BroadcastService } from "@azure/msal-angular";
import { CryptoUtils, Logger } from "msal";
import { BehaviorSubject } from "rxjs";
import { User } from "../models/user";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApplicationPermission } from "../models/applicationPermission";
import { Constants } from "../app.constants";
import { Tokens } from "../models/tokens";
import { EunityConfig } from "../models/eunityconfig";
import { StudyPortalLink } from "../models/studyPortalLink";
import { Notifications } from "../models/notifications";
import { Bookmarks } from "../models/bookmarks";

const GRAPH_ENDPOINT_GROUP = "https://graph.microsoft.com/v1.0/me/memberOf";

@Injectable({
  providedIn: "root",
})
export class AzureAuthService implements OnDestroy {
  ngOnDestroy(): void {
    this.localLogout();
    this.logout();
  }
  // Create stream for user profile data and tokens
  appUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  //set is load
  isBookmarkPopOverLoaded$ = new BehaviorSubject<boolean>(false);
  //set Loading service
  isLoadingService$ = new BehaviorSubject<boolean>(true);
  // Create stream for token
  apiToken$ = new BehaviorSubject<string>(null);
  //studylink source
  studyLink$: BehaviorSubject<StudyPortalLink[]> = new BehaviorSubject(null);
  isStudyLinkIsLoad$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //notificationSubscribe source
  notificationsData$: BehaviorSubject<Notifications[]> = new BehaviorSubject(
    null
  );
  //notificationByUserID source
  notificationsByUserIdData$: BehaviorSubject<Notifications[]> =
    new BehaviorSubject(null);
  //bookmark source
  bookmarksData$: BehaviorSubject<Bookmarks[]> = new BehaviorSubject(null);

  // Authentication navigation
  onAuthSuccessUrl = "/";
  onAuthFailureUrl = "/landing";
  private authFlag = "isLoggedIn";
  loggedIn = false;
  isLoggedIn: boolean = null;

  private apiUrl: string = environment.studyApiUrl + "AppSecurity/";
  private securityApiUrl: string = environment.securityApiUrl + "AppSecurity/";

  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    // private azureAuth: AzureAuthService,
    private http: HttpClient
  ) {}

  initAuthService() {

    this.broadcastService.subscribe("msal:loginSuccess", (item) => {
    //   debugger;
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error("Redirect Error: ", authError.errorMessage);
        return;
      }

    //   console.log("Redirect Success: ", response);
      this.getUserGroup().subscribe((res:any) => {        
        // console.log(res);
        //Save groups to array
        let groups: string[] = [];
        for (var item of res.value) {
            groups.push(item.displayName);
        }

        localStorage.setItem("userGroups", groups.toString());

        //Get all services tokens
        this.getAllServicesTokens(response);
      });
    });
  }

  checkoutAccount() {
    this.loggedIn = !!this.authService.getAccount();
    localStorage.setItem("loggedInStatus", this.loggedIn.toString());
  }

  login() {
    const isIE =
      window.navigator.userAgent.indexOf("MSIE ") > -1 ||
      window.navigator.userAgent.indexOf("Trident/") > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  logout() {
    this.authService.logout();
    sessionStorage.clear();
  }

  localLogout() {
    sessionStorage.setItem(this.authFlag, JSON.stringify(false));
    this.apiToken$.next(null);
    this.appUser$.next(null);
    this.setSession(Constants.ADALToken, null);
    this.setSession(Constants.StudyAPIToken, null);
    this.setSession(Constants.OrgCode, null);
    this.setSession(Constants.AuthToken, null);
  }

  getAllServicesTokens(response) {
    this.setAuthToken(response.idToken.rawIdToken);

    let token = this.getAuthToken();

    var userGroups = localStorage.getItem("userGroups");

    var groups = userGroups.split(',');
    this.getSecurityToken(token, groups).subscribe((data) => {
    //   console.log(data);

      // declare permission list
      var permissionlist: Array<ApplicationPermission> = null;
      if (data["PermissionList"] != null && data["PermissionList"] != "")
        permissionlist = JSON.parse(data["PermissionList"]);

      this.getServiceToken(token, groups).subscribe((data) => {
        var tokens: Tokens = {
          auth0_token: token,
          adal_token: data["ADALToken"],
          study_api_token: data["StudyAPIToken"],
        };
        var eUnity: EunityConfig = {
          eunity_image_access_id: data["EunityImageAccessID"],
          eunity_image_encoding_secret: data["EunityImageEncodingSecret"],
          eunity_image_host: data["EunityImageHost"],
          eunity_image_requested_resource: data["EunityImageRequestedResource"],
        };
        var disableOrgCodes = [];
        if (data["DisableOrgCodes"] != null && data["DisableOrgCodes"] != "") {
          disableOrgCodes = data["DisableOrgCodes"].split(",");
        }
        var user: User = {
          name: response.idTokenClaims.name,
          picture: response.idTokenClaims.picture
            ? response.idTokenClaims.picture
            : "",
          token: tokens,
          user_id: response.idTokenClaims.oid,
          org_codes: data["OrgCodes"],
          eunity_config: eUnity,
          isBookMarked: false,
          isRead: false,
          bookMarkCount: 0,
          notificationCount: 0,
          updated_at: new Date(response.idTokenClaims.iat).toDateString(),
          permissionList: permissionlist,
          disabledOrgCodes: disableOrgCodes,
        };

        //Set EunityConfig to session
        this.setSession(Constants.EunityConfig, JSON.stringify(eUnity));
        //set all Api tokens to session
        this.setSession(Constants.Token, JSON.stringify(tokens));
        //set orgCodes
        this.setSession(Constants.OrgCode, data["OrgCodes"]);
        //set orgCodes
        this.setSession(Constants.DisableOrgCodes, disableOrgCodes);
        if (permissionlist != null)
          this.setSession(Constants.Permission, JSON.stringify(permissionlist));
        else this.setSession(Constants.Permission, null);
        // Emit value for user data subject
        this.appUser$.next(user);
        this.setSession(Constants.AppUser, JSON.stringify(this.appUser$.value));
        this.checkoutAccount();
      });
    });
    // });
  }

  private getSecurityToken(azureIdToken, userGroups) {
    var appname = environment.applicationName;
    const data = JSON.stringify({
      "": {
        authToken: azureIdToken,
        applicationName: appname,
      },
    });

    const data1 = {
      authToken: azureIdToken,
      applicationName: appname,
      groups: userGroups,
    };

    return this.http.post(
      this.securityApiUrl + "GetSecurityTokenFromAzureIdToken",
      data1
    );
  }

  private getServiceToken(auth0token, userGroups) {
    var appname = environment.applicationName;
    const data = {
      authToken: auth0token,
      applicationName: appname,
      groups: userGroups,
    };
    return this.http.post(
      this.apiUrl + "GetServiceTokenFromAzureIdToken",
      data
    );
  }

  refreshAuthentication() {
    this.broadcastService.getMSALItem().forEach((item) => {
      if (item.payload) this.getAllServicesTokens(item.payload);
    });
  }

  getUserGroup() {
    return this.http.get(GRAPH_ENDPOINT_GROUP);
  }

  setAuthToken(token: string) {
    this.setSession(Constants.AuthToken, token);
  }

  getAuthToken() {
    return this.getSession(Constants.AuthToken);
  }

  setSession(key: string, value: any): void {
    sessionStorage.setItem(key, value);
  }

  getSession(key: string) {
    return sessionStorage.getItem(key);
  }

  getUserDataWithToken() {
    let token = this.getAuthToken();
    // var res = JSON.parse(this.getPayLoad());
    var res;
    this.userProfile$.subscribe((x) => {
      if (x != null && x != undefined) res = x;
      else res = this.getAppUser();
    });

    //API tokens
    var tkn = JSON.parse(this.getSession(Constants.Token));
    var tokens: Tokens = {
      auth0_token: token,
      adal_token: tkn["adal_token"],
      study_api_token: tkn["study_api_token"],
    };

    //eUnityConfig
    var eConfig = JSON.parse(this.getSession(Constants.EunityConfig));
    var eUnity: EunityConfig = {
      eunity_image_access_id: eConfig["eunity_image_access_id"],
      eunity_image_encoding_secret: eConfig["eunity_image_encoding_secret"],
      eunity_image_host: eConfig["eunity_image_host"],
      eunity_image_requested_resource:
        eConfig["eunity_image_requested_resource"],
    };
    var permissionlist: Array<ApplicationPermission> = null;
    if (this.getSession(Constants.Permission) != null)
      permissionlist = JSON.parse(this.getSession(Constants.Permission));

    var disableOrgCodes = [];
    if (
      this.getSession(Constants.DisableOrgCodes) != null &&
      this.getSession(Constants.DisableOrgCodes) != ""
    ) {
      disableOrgCodes = this.getSession(Constants.DisableOrgCodes).split(",");
    }
    var user: User = {
      name: res["name"],
      picture: res["picture"],
      token: tokens,
      user_id: res["sub"],
      org_codes: this.getSession(Constants.OrgCode) as any,
      eunity_config: eUnity,
      isBookMarked: false,
      isRead: false,
      bookMarkCount: 0,
      notificationCount: 0,
      updated_at: res["updated_at"],
      permissionList: permissionlist,
      disabledOrgCodes: disableOrgCodes,
    };
    // Emit value for user data subject
    this.appUser$.next(user);
    this.setSession(Constants.AppUser, JSON.stringify(this.appUser$.value));
  }

  getAppUser() {
    var x = JSON.parse(this.getSession(Constants.AppUser));
    if (x == null) {
      this.setSession(Constants.AppUser, JSON.stringify(this.appUser$.value));
      x = JSON.parse(this.getSession(Constants.AppUser));
    }
    return x;
  }
}
