export const environment = {
  production: true,
  hostUrl: "",
  auth: {
    CLIENT_ID: 'P6lMDpZ8oLtJd90ypYnaJw1b8T6YYDl4',
    CLIENT_DOMAIN: 'lucidworkflow.auth0.com', // e.g., 'you.auth0.com'
    REDIRECT: `${window.location.origin}`,
    LOGOUT_URL: `${window.location.origin}`
  },
  applicationName:"Study Portal",
  permissonNameOfPortalLink:"Enable Study Portal Links",
  studyApiUrl: "https://lh-study-services-prod.azurewebsites.net/api/",
  studyReportUrl: "https://lwf-studyreport-service-eastus.azurewebsites.net/api/",
  oranizationApiUrl:"https://lwf-organization-service-eastus.azurewebsites.net/api/",
  securityApiUrl: "https://lh-security-api.azurewebsites.net/api/",
  redirectUrl: "https://lh-studyportal-ng-prod.azurewebsites.net/"
};
