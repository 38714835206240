

import { Injectable } from '@angular/core';
// import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, bindNodeCallback, from, Observable, throwError, combineLatest, of } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Tokens } from '../models/tokens';
import { Constants } from '../app.constants';
import { EunityConfig } from '../models/eunityconfig';
import { ApplicationPermission } from '../models/applicationPermission';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import createAuth0Client from '@auth0/auth0-spa-js';
import { shareReplay, catchError, tap, concatMap } from 'rxjs/operators';
import { StudyPortalLink } from '../models/studyPortalLink';
import { Bookmarks } from '../models/bookmarks';
import { Notifications } from '../models/notifications';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private apiUrl: string = environment.studyApiUrl + "AppSecurity/";
  private securityApiUrl: string = environment.securityApiUrl + "AppSecurity/"

  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: environment.auth.CLIENT_DOMAIN,
      client_id: environment.auth.CLIENT_ID,
      redirect_uri: `${window.location.origin}`
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError(err => throwError(err))
  );

  private authFlag = 'isLoggedIn';
  private expiresAt: number
  //set is load 
  isBookmarkPopOverLoaded$ = new BehaviorSubject<boolean>(false);
  //set Loading service
  isLoadingService$ = new BehaviorSubject<boolean>(true);
  // Create stream for token
  apiToken$ = new BehaviorSubject<string>(null);
  // Create stream for user profile data and tokens
  appUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  //studylink source
  studyLink$: BehaviorSubject<StudyPortalLink[]> = new BehaviorSubject(null);
  isStudyLinkIsLoad$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  //notificationSubscribe source
  notificationsData$: BehaviorSubject<Notifications[]> = new BehaviorSubject(null);
  //notificationByUserID source
  notificationsByUserIdData$: BehaviorSubject<Notifications[]> = new BehaviorSubject(null);
  //bookmark source
  bookmarksData$: BehaviorSubject<Bookmarks[]> = new BehaviorSubject(null);
  // Authentication navigation
  onAuthSuccessUrl = '/';
  onAuthFailureUrl = '/landing';
  logoutUrl = environment.auth.LOGOUT_URL;

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  // Create observable of Auth0 parseHash method to gather auth results
  // parseHash$ = bindNodeCallback(this.Auth0.parseHash.bind(this.Auth0));

  // Create observable of Auth0 checkSession method to
  // verify authorization server session and renew tokens
  //checkSession$ = bindNodeCallback(this.Auth0.checkSession.bind(this.Auth0));
  constructor(private router: Router, private http: HttpClient) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}`,
        appState: { target: redirectPath }
      });
    });
  }

  handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processsed
      sessionStorage.setItem(this.authFlag, JSON.stringify(true));
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap(cbRes => {
          // Get and set target redirect route from callback results
          this.getIdTokenClaims$().subscribe(() => {
            this.getUserData();
          });
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.isAuthenticated$,
            // this.getIdTokenClaims$(),
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        // Redirect to target route after callback processing
        this.router.navigate([targetRoute]);
      });
    }
    else {
      this.getIdTokenClaims$().subscribe(() => {
        this.getUserDataWithToken();
      });
    }
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => this.userProfileSubject$.next(user))
    );
  }

  getIdTokenClaims$(options?): Observable<any> {
    var claims = this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getIdTokenClaims(options)))
    );

    claims.subscribe(claims => {
      if (claims != null && claims != undefined) {
        this.setAuthToken(claims['__raw']);
      }
    })
    return claims;
  }

  setAuthToken(token: string) {
    this.setSession(Constants.AuthToken, token);
  }
  getAuthToken() {
    return this.getSession(Constants.AuthToken);
  }

  async getUserData(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let token = this.getAuthToken();
      var res;
      this.userProfile$.subscribe(x => {
        res = x;
      });
      this.getSecurityToken(token).subscribe (data => {
        // declare permission list
        var permissionlist: Array<ApplicationPermission> = null;
        if (data["PermissionList"] != null && data["PermissionList"] != "")
          permissionlist = JSON.parse(data["PermissionList"]);
        
        this.getServiceToken(token).subscribe(data => {  
          var tokens: Tokens = {
            auth0_token: token,
            adal_token: data["ADALToken"],
            study_api_token: data["StudyAPIToken"]
          }
          var eUnity: EunityConfig = {
            eunity_image_access_id: data["EunityImageAccessID"],
            eunity_image_encoding_secret: data["EunityImageEncodingSecret"],
            eunity_image_host: data["EunityImageHost"],
            eunity_image_requested_resource: data["EunityImageRequestedResource"]
          }
          var disableOrgCodes = [];
          if (data["DisableOrgCodes"] != null && data["DisableOrgCodes"] != "") {
            disableOrgCodes = data["DisableOrgCodes"].split(',');
          }
          var user: User = {
            name: res["name"],
            picture: res["picture"],
            token: tokens,
            user_id: res["sub"],
            org_codes: data["OrgCodes"],
            eunity_config: eUnity,
            isBookMarked: false,
            isRead: false,
            bookMarkCount: 0,
            notificationCount: 0,
            updated_at: res["updated_at"],
            permissionList: permissionlist,
            disabledOrgCodes: disableOrgCodes
          };
          //Set EunityConfig to session
          this.setSession(Constants.EunityConfig, JSON.stringify(eUnity));
          //set all Api tokens to session
          this.setSession(Constants.Token, JSON.stringify(tokens))
          //set orgCodes
          this.setSession(Constants.OrgCode, data["OrgCodes"]);
          //set orgCodes
          this.setSession(Constants.DisableOrgCodes, disableOrgCodes);
          if (permissionlist != null)
            this.setSession(Constants.Permission, JSON.stringify(permissionlist));
          else
            this.setSession(Constants.Permission, null);
          // Emit value for user data subject
          this.appUser$.next(user);
          this.setSession(Constants.AppUser, JSON.stringify(this.appUser$.value));
          resolve();
        }, err => {
          reject()
        })
      }, err => {
        reject();
      })
    });

  }
  getUserDataWithToken() {
    let token = this.getAuthToken();
    // var res = JSON.parse(this.getPayLoad());
    var res;
    this.userProfile$.subscribe(x => {
      if (x != null && x != undefined)
        res = x;
      else
        res = this.getAppUser();
    });

    //API tokens
    var tkn = JSON.parse(this.getSession(Constants.Token));
    var tokens: Tokens = {
      auth0_token: token,
      adal_token: tkn["adal_token"],
      study_api_token: tkn["study_api_token"]
    }

    //eUnityConfig
    var eConfig = JSON.parse(this.getSession(Constants.EunityConfig));
    var eUnity: EunityConfig = {
      eunity_image_access_id: eConfig["eunity_image_access_id"],
      eunity_image_encoding_secret: eConfig["eunity_image_encoding_secret"],
      eunity_image_host: eConfig["eunity_image_host"],
      eunity_image_requested_resource: eConfig["eunity_image_requested_resource"]
    }
    var permissionlist: Array<ApplicationPermission> = null;
    if (this.getSession(Constants.Permission) != null)
      permissionlist = JSON.parse(this.getSession(Constants.Permission));

    var disableOrgCodes = [];
    if (this.getSession(Constants.DisableOrgCodes) != null && this.getSession(Constants.DisableOrgCodes) != "") {
      disableOrgCodes = this.getSession(Constants.DisableOrgCodes).split(',');
    }
    var user: User = {
      name: res["name"],
      picture: res["picture"],
      token: tokens,
      user_id: res["sub"],
      org_codes: this.getSession(Constants.OrgCode) as any,
      eunity_config: eUnity,
      isBookMarked: false,
      isRead: false,
      bookMarkCount: 0,
      notificationCount: 0,
      updated_at: res["updated_at"],
      permissionList: permissionlist,
      disabledOrgCodes: disableOrgCodes
    };
    // Emit value for user data subject
    this.appUser$.next(user);
    this.setSession(Constants.AppUser, JSON.stringify(this.appUser$.value));
  }

  private handleError(err) {
    if (err.error_description) {
      console.error(`Error: ${err.error_description}`);
    } else {
      console.error(`Error: ${JSON.stringify(err)}`);
    }
    //Remove flag
    sessionStorage.setItem(this.authFlag, JSON.stringify(false));
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: environment.auth.CLIENT_ID,
        returnTo: `${window.location.origin}`
      });
    });
    sessionStorage.clear();
  }

  localLogout() {
    sessionStorage.setItem(this.authFlag, JSON.stringify(false));
    this.apiToken$.next(null);
    this.appUser$.next(null);
    this.setSession(Constants.ADALToken, null);
    this.setSession(Constants.StudyAPIToken, null);
    this.setSession(Constants.OrgCode, null);
    this.setSession(Constants.AuthToken, null);
  }

  private getServiceToken(auth0token) {
    var appname = environment.applicationName;
    const data = JSON.stringify({
      "authToken": auth0token,
      "applicationName": appname
    });
    return this.http.post(this.apiUrl + "GetServiceToken", data);
  }

  private getSecurityToken(auth0token) {
    var appname = environment.applicationName;
    const data = JSON.stringify(
      { "" : 
        {
          "authToken": auth0token,
          "applicationName": appname
        } 
      });
    
    const data1 = {
      "authToken": auth0token,
      "applicationName": appname
    } 
    
    return this.http.post(this.securityApiUrl + "GetSecurityToken", data1);
  }

  setSession(key: string, value: any): void {
    sessionStorage.setItem(key, value);
  }
  getSession(key: string) {
    return sessionStorage.getItem(key);
  }

  getAppUser() {
    var x = JSON.parse(this.getSession(Constants.AppUser));
    if (x == null) {
      this.setSession(Constants.AppUser, JSON.stringify(this.appUser$.value));
      x = JSON.parse(this.getSession(Constants.AppUser));
    }
    return x
  }
}
